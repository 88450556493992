<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">

        <userleft currindex=9  />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">账号设置<em v-if="userinfo != null && userinfo.iDcardAuthStatus == 1"> 
                <router-link
                :to="{
                  path: '/jobwanted/myResumeperfect',
                  query: { id: candidateId }
                }"
                class="content_button"
                v-if="isResume"
                ><i class="resume"></i>个人简历</router-link
              >
                </em>
                </h3>
              <div class="info-cnt">
                <div class="content-rb">
                  <el-tabs type="border-card" v-model="activeName">
                    <el-tab-pane label="注册信息" name="first">
                      <template v-if="userinfo!=null">
                        <div class="user">
                          <div class="user-left">
                            <div class="user_list">
                              <i class="el-icon-goods"></i>
                              <span >昵称：
                                <span v-if="iseditusername">{{userinfo.userNickName}}</span> 
                                <span v-if="!iseditusername"><el-input size="mini" v-model="userinfo.userNickName" placeholder="请输入昵称"></el-input></span>
                              </span>
                              <el-button v-if="iseditusername" type="text" @click="iseditusername=false" >修改</el-button>
                              <el-button v-if="!iseditusername" type="text" @click="editusername" >确认</el-button>
                              <el-button v-if="!iseditusername" type="text" @click="iseditusername=true" >取消</el-button>
                            </div>
                            <div class="user_list">
                              <i class="el-icon-user"></i>
                              <span >账号：
                                <span v-if="iseditmobile">{{userinfo.userMobile}}</span> 
                                <span v-if="!iseditmobile">
                                  <el-input size="mini" v-model="userinfo.userMobile" placeholder="请输入账号"></el-input>
                                  验证码：<el-input size="mini" v-model="verifCode" placeholder="请输入验证码"></el-input>
                                </span>
                                
                              </span>
                              <el-button v-if="iseditmobile" type="text" @click="iseditmobile=false" >修改</el-button>
                              <el-button v-if="!iseditmobile" type="text" @click="sendmobilesm" >{{codeMsg}}</el-button>
                              <el-button v-if="!iseditmobile" type="text" @click="editmobile" >确认</el-button>
                              <el-button v-if="!iseditmobile" type="text" @click="iseditmobile=true" >取消</el-button>
                            </div>
                            <div class="user_list">
                              <i class="el-icon-time"></i>
                              <span v-if="userinfo.createAt!=null">注册时间：{{userinfo.createAt.substring(0,10)}}</span>
                            </div>
                            <div class="user_list">
                              <i class="el-icon-menu"></i>
                              <span>二维码</span>
                              <div class="user_img">
                                <el-image :src="bigqrcodeurl" ></el-image>
                              </div>
                            </div>
                          </div>
                          <div class="user-right">
                            <div>
                              <el-image :src="userinfo.userAvatar" :onerror="defaultImg"></el-image>
                            </div>
                            <div>
                              <el-upload class="upload-demo" :headers="myHeaders" 
                                 :action="uploadUrl" name="file"
                                accept="image/png,image/gif,image/jpg,image/jpeg" :file-list="fileList" :on-success="busSuccess"
                                :on-error="busError" :show-file-list="false">
                              <el-button type="primary" size="mini" plain>修改头像</el-button>
                              </el-upload>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-if="userinfo==null">
                        <div class="empty">
                          <i class="icon el-icon-sunrise"></i>
                          <span>暂无信息</span>
                        </div>
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
              <div class="info-cnt">  
                <div class="content-rb">
                  <div class="btnaddcompany">
                    <el-button type="primary" @click="applyrealname" v-if="userinfo!=null&&userinfo.iDcardNO!=''"><i
                        class="el-icon-circle-plus-outline el-icon--left"></i>重新认证</el-button>
                        <el-button type="primary" @click="applyrealname" v-if="userinfo==null||userinfo.iDcardNO==''"><i
                        class="el-icon-circle-plus-outline el-icon--left"></i>申请认证</el-button>
                  </div>
                  <el-tabs type="border-card" v-model="activeName">
                    <el-tab-pane label="实名认证信息" name="first">
                      <template v-if="userinfo!=null">
                        <div class="user">
                          <div class="user-left">
                            <div class="user_list">
                              <span class="bigname"> {{userinfo.userNickName}} </span>
                            </div>
                            <div class="user_list">
                              <i class="el-icon-user"></i>
                              <span>{{userinfo.iDcardSex}} / {{userinfo.iDcardBirthdate}} / {{userinfo.userAge+"岁"}}</span> 
                            </div>
                            <div class="user_list">
                              <i class="el-icon-goods"></i>
                              <span>身份证号：{{userinfo.iDcardNO}}</span> 
                            </div>
                            <div class="user_list">
                              <i class="el-icon-position"></i>
                              <span>住址：{{userinfo.iDcardAddress}}</span> 
                            </div>
                            <div class="user_list">
                              <i class="el-icon-office-building"></i>
                              <span>签发机关：{{userinfo.iDcardIssuingAuthority}}</span> 
                            </div>
                            <div class="user_list">
                              <i class="el-icon-time"></i>
                              <span>有效期限：{{userinfo.iDcardValidityperiodStart+"-"}}{{userinfo.iDcardValidityperiodEnd}}</span> 
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-if="userinfo==null">
                        <div class="empty">
                          <i class="icon el-icon-sunrise"></i>
                          <span>暂无信息</span>
                        </div>
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
              <div class="info-cnt">  
                <div class="content-rb">
                  <div class="btnaddcompany">
                    <el-button type="primary" @click="applyprofession"><i
                        class="el-icon-circle-plus-outline el-icon--left"></i>申请认证</el-button>
                  </div>
                  <el-tabs type="border-card" v-model="activeName">
                    <el-tab-pane label="个人专业职称" name="first">
                      <template v-if="professioncertlist.length>=1">
                        <div class="personal" v-for="(item,index) in professioncertlist" :key="index">
                          <div class="personal-title">
                            <span>{{item.certType+'('+item.certTypeList[0]+')'}}</span>
                            <div class="button">
                              <el-button type="text" @click="change(item.id)" >变更</el-button>
                              <el-button type="text" @click="cancellation(item.id)" >注销</el-button>
                            </div>
                            <div class="clear"></div>
                          </div>
                          <div class="personal-content">
                            <div class="behave">
                              <div v-if="userinfo!=null&&userinfo.iDcardName!=''">姓名：{{userinfo.iDcardName}}</div>
                              <div>职称类目：{{item.certTypeList.join(">")}}</div>
                              <div>批准日期：{{item.approvalDate}}</div>
                              <div>管理号：{{item.managementNO}} </div>
                            </div>
                            <el-image :src="item.certImgOUrl" class="personal-img"></el-image>
                            <div class="clear"></div>
                            <div class="addapply" @click="addapply(item.id)" v-if="item.certTypeList[0]==='注册类' && item.registerNO=== null && item.employer === null && item.verifyStatus === 1">
                              <i class="el-icon-plus"></i>
                              <span>申请注册认证</span>
                            </div>
                            <div v-if="item.registerNO!=null&&item.employer!=null">
                              <div class="behave">
                                <div>注册号：{{item.registerNO}}</div>
                                <div>执业机构：{{item.employer}}</div>
                                <div>签发单位：{{item.issuingAuthority}}</div>
                                <div>有效期至：{{item.expiryDate}}</div>
                              </div>
                              <div class="button">
                                <div v-if="item.verifyStatus === 1">
                                  <el-button type="text" @click="changeregister(item.id)" >变更</el-button>
                                  <el-button type="text" @click="cancellationregister(item.id)" >注销</el-button>
                                </div>
                                <el-image :src="item.certRegistImgOUrl" class="personal-imgreg"></el-image>
                              </div>
                              <div class="clear"></div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-if="professioncertlist.length==0">
                        <div class="empty">
                          <i class="icon el-icon-sunrise"></i>
                          <span>暂无信息</span>
                        </div>
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <div class="dialogs">
        验证码：<el-input  v-model="promsend.VerifyCode" placeholder="请输入验证码"></el-input>
        <el-button  type="primary" @click="sendcode" >{{codeMsg}}</el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="logout">注 销</el-button>
      </span>
    </el-dialog>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import userleft from '@/components/userleft.vue';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import { candidateId } from '@/api/jobwant';
import { 
  usergetcurruserinfo ,
  utilsgetuserqrcodebase64,
  usermodifynickname,
  usersendchangeusermobilesms,
  usermodifyavatar,
  professioncertlist,
  sendprofessioncertlogoutverifysms,
  sendprofessioncertreglogoutverifysms,
  professioncertlogout,
  professioncertreglogout,
  } from '@/api/user';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    return {
      dialogVisible:false,
      title:'专业资格注销',
      promsend:{
        VerifyCode:'',
        Id:''
      },
      fileList: [],
      form: {
        userNickName: '',
        userAvatar: '',
        userAvatarUrl: '',
      },
      myHeaders: { Authorization: 'Bearer ' + token },
      uploadUrl: this.$uploadUrlN + '/utils/uploadfile',
      iseditusername:true,
      iseditmobile:true,
      verifCode:'',
      codeMsg:'获取验证码',
      bullet:1,
      second: 60,
      timer: null,
      professioncertlist:[],
      activeName: 'first',
      firstShow: true,
      show: false,
      height: '600',
      userinfo:null,
      bigqrcodeurl:'',
      defaultImg:'this.src="' + require('@/assets/img/defaultheadpic.png') + '"',
      isResume:false
    };
  },
  methods: {
    addapply(val){
      this.$router.push({
        path:"/user/authenticationreg",
        query:{id:val}
      })
    },
    cancellation(val){
      this.title = '专业资格注销'
      this.bullet = 1
      this.dialogVisible = true
      this.promsend.Id = val
    },
    cancellationregister(val){
      this.title = '专业资格注册注销'
      this.bullet = 2
      this.dialogVisible = true
      this.promsend.Id = val
    },
    sendcode(){
      if (this.second != 60) {
        this.$message.error(`请${this.second}秒后重试`);
        return;
      }
      if(this.bullet === 1){
        sendprofessioncertlogoutverifysms().then(res=>{
          if(res.data.code === 1){
            this.$message.success('发送成功');
            this.codeMsg = `${this.second}s后重试`;
            this.timer = setInterval(() => {
              this.second--;
              this.codeMsg = `${this.second}s后重试`;
              if (this.second == 0) {
                clearInterval(this.timer);
                this.second = 60;
                this.codeMsg = '获取验证码';
              }
            }, 1000);
          }else{
            this.$message.error(res.data.message)
          }
        })
      }else if(this.bullet === 2){
        sendprofessioncertreglogoutverifysms().then(res=>{
          if(res.data.code === 1){
            this.$message.success('发送成功');
            this.codeMsg = `${this.second}s后重试`;
            this.timer = setInterval(() => {
              this.second--;
              this.codeMsg = `${this.second}s后重试`;
              if (this.second == 0) {
                clearInterval(this.timer);
                this.second = 60;
                this.codeMsg = '获取验证码';
              }
            }, 1000);
          }else{
            this.$message.error(res.data.message)
          }
        })
      }

    },
    logout(){
      if (this.promsend.VerifyCode == '') {
        this.$message.error('请输入验证码');
        return;
      }
      if(this.bullet === 1){
        professioncertlogout(this.promsend).then(res=>{
          if(res.data.code === 1){
            this.$message.success('注销成功');
          }else{
            this.$message.error(res.data.message)
          }
        })
      }else if(this.bullet === 2){
        professioncertreglogout(this.promsend).then(res=>{
          if(res.data.code === 1){
            this.$message.success('注销成功');
          }else{
            this.$message.error(res.data.message)
          }
        })
      }
    },
    handleClose(){
      this.dialogVisible = false
    },
    change(item){
      this.$router.push({ path:'/user/authentication',query:{id:item}})
    },
    changeregister(item){
      this.$router.push({ path:'/user/authenticationreg',query:{id:item}})
    },
     applyprofession(){
      this.$router.push({ path:'/user/authentication'})
    },
    onprofessioncertlist() {
      professioncertlist().then((res) => {
        if (res.data.code === 1) {
          this.professioncertlist = res.data.result;
        }
      });
    },
        // 根据完善信息id进行判断是否进入完善页面
    getCandidateId() {
      candidateId().then(res => {
        console.log(res.data.result);
        if (res.data.result !== null && res.data.result > 0) {
          this.isResume = true;
          this.candidateId = res.data.result;
        }
      });
    },
    applyrealname(){
      this.$message("请至牛豆豆APP进行实名认证")
    },
    busSuccess(response) {
      if (response.code == 1) {
        this.form.userAvatar = response.result.fileToken;
        this.form.userAvatarUrl = response.result.url + '/image/256x';
        usermodifyavatar({ filetoken: this.form.userAvatar }).then((res) => {
          if(res.data.code === 1){
            this.$message.success('头像已更新');
            this.onusergetcurruserinfo()
          }
        });
      }
    },
    busError() {
      fileList = [];
      return this.$message.error('上传失败');
    },
    isShow() {
      this.show = !this.show;
    },
    hideDetail() {
      this.detailShow = false;
    },
    onusergetcurruserinfo() {
      var that = this;
      usergetcurruserinfo({}).then((res) => {
        if (res.data.code === 1) {
            that.userinfo=res.data.result;
        }
      });
    },
    onutilsgetuserqrcodebase64() {
      let params = { desUId:getCookieByName('user_id')  };
      utilsgetuserqrcodebase64(params).then(res => {
        if (res.data.result !== null) {
          this.bigqrcodeurl = 'data:image/jpeg;base64,' + res.data.result;
        }
      });
    },
    sendmobilesm(){
      if (this.userinfo.userMobile == '') {
        this.$message.error('请输入手机号');
        return;
      }
      if (this.second != 60) {
        this.$message.error(`请${this.second}秒后重试`);
        return;
      }
      usersendchangeusermobilesms({userMobile:this.userinfo.userMobile}).then(res=>{
        if(res.data.code === 1){
          this.$message.success('发送成功');
          this.codeMsg = `${this.second}s后重试`;
          this.timer = setInterval(() => {
            this.second--;
            this.codeMsg = `${this.second}s后重试`;
            if (this.second == 0) {
              clearInterval(this.timer);
              this.second = 60;
              this.codeMsg = '获取验证码';
            }
          }, 1000);
        }else{
          this.$message.error(res.data.message)
        }
      })
    },
    editusername(){
      usermodifynickname({ nickName: this.userinfo.userNickName }).then(res=>{
        if(res.data.code === 1){
          this.$message.success('修改成功');
          this.iseditusername = true
          this.onusergetcurruserinfo()
        }
      })
    },
    editmobile(){
      let form = {
        newUserMobile: this.userinfo.userMobile,
        verifCode: this.verifCode,
      };
      userchangeusermobile(form).then((res) => {
        if (res.data.code === 1) {
          this.$message.success('修改成功');
          this.iseditmobile = true
          this.onusergetcurruserinfo()
        }
      });
    },
    ongetuserinfo(){
        usergetcurruserinfo().then(res => {
        if (res.data.code == 1) {
          this.userinfo = res.data.result;
          if (that.userinfo.userAvatar) {
            that.userAvatar = that.userinfo.userAvatar;
          }
          if (that.userinfo.userNickName) {
            that.userNickName = this.userinfo.userNickName;
          }
        }
      });
    }
  },
  mounted() {
    screenLeft;
    this.onprofessioncertlist();
    this.onusergetcurruserinfo();
    this.onutilsgetuserqrcodebase64();
    this.getCandidateId();
  },
 
};
</script>
<style  scoped >
.dialogs .el-input{
  width: 200px;
  margin-right: 10px;
}
.clear{
  clear: both;
}
.content-rb .personal{
  margin-top: 10px;
  padding-top: 5px;
  border: 1px solid #EBEEF5;
  min-height: 150px;
}
.content-rb .personal .personal-content{
  padding: 10px 20px 5px 20px;
  font-size: 14px;
  line-height: 30px;
}
.content-rb .personal .personal-content .addapply{
  text-align: center;
  cursor: pointer;
  color: #7C7A79;
}
.content-rb .personal .personal-content .addapply i{
  color: #CCCBCC;
  font-size: 16px;
  margin-right: 5px;
  font-weight: 600;
}
.content-rb .personal .personal-content .behave{
  float: left;
}
.content-rb .personal .personal-title{
  padding: 0 20px 5px 20px;
  border-bottom: 1px solid #EBEEF5;
}
.content-rb .personal .personal-title span{
  font-size: 15px;
  padding-top: 12px;
  float: left;
}
.content-rb .personal .personal-content .personal-img{
  float: right;
  max-width: 100px;
  max-height: 100px;
}
.content-rb .personal .personal-content .personal-imgreg{
  max-width: 100px;
  max-height: 100px;
}
.content-rb .personal .button{
  float: right;
}
.content-rb .user{
  padding:20px 10px 10px 20px ;
}
.content-rb .user i{
  font-size: 15px;
  margin-right: 5px;
}
.content-rb .user .user_list{
  margin-bottom: 10px;
  font-size: 14px;
}
.content-rb .user .user_list .bigname{
  font-size: 22px;
  font-weight: 500;
}
.content-rb .user .user_list .el-input{
  width: 150px;

}
.content-rb .user .user_list .el-input.el-input--mini >>>.el-input__inner{
  height: 24px;
}
.content-rb .user .user_list >>> .el-button{
  padding: 0;
  margin-left: 5px;
}
.content-rb .user .user_list .user_img .el-image{
  margin:10px 0 0 20px;
  width: 130px;
  height: 130px;
}
.content-rb .user .user-left{
  float: left;
}
.content-rb .user .user-right {
  float: right;
  padding-right:70px ;
  text-align: center;
}
.content-rb .user .user-right .el-image {
  text-align: center;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}




body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.account-nav li a i.card,
.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  color: #404040;
  font-style: normal;
  margin-left: 10px;
}
.accountset .item-block .title em a{
  color: #0d7bff;

}

.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .content-rb .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.accountset .content-rb .empty i {
  font-size: 40px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.accountset .content-rb .empty span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #ddd;
}
.accountset .content-rb .btnaddcompany {
  position: absolute;
  right: 36px;
  z-index: 100;
}
.accountset .content-rb .btnaddcompany button {
  padding: 6px 6px;
  margin-top: 6px;
}
.accountset .content-rb .btnaddcert button {
  margin: 10px 0;
  padding: 6px 6px;
}
.accountset .content-rb1 {
  float: left;
  margin: 20px 6px 5px 20px;
  width: 253px;
  height: 295px;
  background-color: #fafbfc;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 3px 8px 0 rgba(47, 24, 24, 0.1);
  transition: all .2s linear;
}
.accountset .content-rb1:hover {
  transform: translate3d(0,-2px,0);
}
.accountset .content-rb1 .rb1-ico {
  background-image: url('../../assets/img/auth-index-ico.png');
  background-repeat: no-repeat;
}
.accountset .content-rb1 > div:nth-of-type(1) {
  position: absolute;
  top: 0;
  right: 0;
}
.accountset .content-rb1 > div:nth-of-type(2) {
  width: 95px;
  height: 75px;
  margin: auto;
  margin-top: 55px;
  /* border: 1px solid red; */
}
.accountset .content-rb1 > p:nth-of-type(1) {
  position: absolute;
  font-weight: 500;
  top: 140px;
  left: 95px;
  font-size: 16px;
}
.accountset .content-rb1 > p:nth-of-type(2) {
  width: 208px;
  position: absolute;
  font-weight: 100;
  top: 177px;
  left: 22px;
  font-size: 10px;
  /* border: 1px solid red; */
  text-align: center;
  font-size: 13px;
}
.accountset .content-rb1 > a {
  display: block;
  width: 195px;
  height: 40px;
  position: absolute;
  font-size: 10px;
  color: #fff;
  cursor: pointer;
  top: 215px;
  left: 30px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  /* border: 1px solid #0d7bff; */
  background-image: linear-gradient(260deg, #5aa5ff, #0d7bff);
}
.accountset .content-right a {
  padding-bottom: 0;
}
</style>
